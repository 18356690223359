import { TFunction } from 'react-i18next';

import { WithDeleted } from '@common/typescript/objects/WithDeleted';
import { Nullable } from '@common/typescript/objects/Nullable';
import { WithId } from '@common/typescript/objects/WithId';
import { pureEnum } from '@common/typescript/utils/enums';
import { WithRemoved } from '@common/typescript/objects/WithRemoved';

import { Clinic } from '@app/objects/Clinic';
import { User } from '@app/objects/User';
import { Crematory } from '@app/objects/Crematory';
import { Price, PriceType } from '@app/objects/Price';
import { Invoice } from '@app/objects/Invoice';
import { CurrentStatus } from '@app/objects/CurrentStatus';
import { Location, LocationInfo } from '@app/objects/Location';
import { BatchAction } from '@app/objects/BatchAction';
import { Carrier } from '@app/objects/Carriers';
import { SpecialServiceType } from '@app/objects/SpecialService';
import { InventoryItem } from '@app/objects/Inventory';
import { MailStatus } from '@app/objects/EmailLog';
import { PetFile } from '@app/objects/PetFile';
import { State } from '@app/objects/State';
import { StoreEntry, StoreEntryNode } from '@app/objects/StoreEntry';
import { PickupService } from '@app/objects/PickupService';

export enum DeliveryType
{
	HomeDropOff = 0,
	VetDropOff = 1,
	Clinic = 2,
	Mail = 3,
	Pickup = 4,
	None = 5,
}

export enum DeliveryTypeName {
	HomeDropOff = 'home-drop-off',
	VetDropOff = 'vet-drop-off',
	Clinic = 'clinic',
	Mail = 'mail',
	Pickup = 'pick-up',
	None = 'none',
}

export enum InvoiceFilterStrategy {
	Any = 0,
	Invoiced = 1,
	NotInvoiced = 2,
}

pureEnum(DeliveryType);

export enum ServiceType
{
	All = -1,
	Communal = 0,
	Private = 1,
	SemiPrivate = 2,
	Storage = 3,
	ProductsOnly = 4,
}

pureEnum(ServiceType);

export enum PetSource
{
	Internal = 0,
	External = 1,
}

export enum HasIssues
{
	WithoutIssues = 0,
	WithIssues = 1,
}

export enum WeightSource
{
	ActualWeight = 0,
	ReportedWeight = 1,
}

export interface Gender extends WithId {
	name: string,
	description: string,
}

export interface PetSpecie extends WithDeleted {
	name: string;
}

export interface PetBreed extends WithDeleted {
	name: string;

	petSpecieId: number;
	petSpecie?: PetSpecie;
}

export interface PetPrice extends WithId, WithRemoved {
	clientId: string;

	petId: number;
	pet?: Pet | null;

	priceId: number;
	price?: Price | null;

	count: number;
	done: boolean;
	completedCount: number;

	value: number;
	extra: number;
	name: string;

	editor: User | null;
	editorId: number | null;

	pickupService: Nullable<PickupService>;
	pickupServiceId: Nullable<number>;

	batchCount: number;
	batchPrice: number;

	entry?: Nullable<StoreEntry>;
	node: Nullable<StoreEntryNode>;
	nodeId: Nullable<number>;
	toSelect?: boolean;

	note: string;
}

export interface PetNote extends WithId, WithRemoved {
	description: string;
	date: string;
	user: Nullable<User>;
}

export interface PetUrn extends WithId, WithRemoved {
	clientId: string;
	petId: number;
	categoryId: number;
	category?: Nullable<InventoryItem>;
	sizeId: Nullable<number>;
	size?: Nullable<InventoryItem>;
	count: number;
	value?: number;
}

export interface PetEngraving extends WithId, WithRemoved{
	name: string;
	order: number;
}

export interface PetInvoiceSummary {
	servicesTotal: number;
	productsTotal: number;
	deliveryTotal: number;
	subTotal: number;

	serviceTax: number;
	serviceTaxPercentage: number;
	productTax: number;
	productTaxPercentage: number;

	total: number;
}

export enum PetOnHold {
	None = 0,
	ByClinic = 1,
	ByCrematory = 2,
}

export interface Pet extends WithId {
	spreadLong: Nullable<string>;
	spreadLat: Nullable<string>;
	coordinatesMailStatus?: MailStatus;

	specialInstructions: string;
	notes: Array<PetNote>;

	batchActionId: number | null;
	batchAction: BatchAction | null;
	batchMachineLocation: string;

	deliveryType: DeliveryType;
	deliveryAddress: string;
	deliveryAddress2: string;
	deliveryCity: string;
	deliveryZip: string;

	deliveryStateId: Nullable<number>;
	deliveryState: Nullable<State>;

	deliveryClinicId: Nullable<number>;
	deliveryClinic: Nullable<Clinic>;

	trackingNumber?: string;
	sendTrackingEmail?: Nullable<boolean>;
	carrier?: Nullable<Carrier>;
	carrierId?: Nullable<number>;

	discountId: Nullable<number>;
	discount: Nullable<Price>;

	serviceType: ServiceType;
	isSpecial: boolean;

	onHold: PetOnHold;
	rush: boolean;

	phone: string;
	email: string;

	clinicName: string;

	actualWeight: number;
	reportedWeight: number;
	ashesWeight: number;

	createdDate: number;
	receivedDate: number;

	name: string;
	engraving: Nullable<Array<PetEngraving>>;

	gender: Gender | null;
	genderId: number,
	color: string;

	petSpecieId: number;
	petSpecie: PetSpecie | null;

	petBreedId: number;
	petBreed: PetBreed | null;
	isMixed: boolean;

	petSource: PetSource;

	clinicId: number | null;
	clinic?: Clinic | null;

	clinicLocationId: number | null;
	clinicLocation?: Location | null;

	userId: number;
	user?: User;

	crematoryId: number;
	crematory?: Crematory;

	urns: Array<PetPrice>;
	products: Array<PetPrice>;

	vet: string;
	internalIdNum: string;

	ownerFirstName: string;
	ownerLastName: string;
	ownerPhone: string;
	ownerPhone2: string;
	ownerEmail: string;
	ownerAddress: string;
	ownerAddress2: string;
	ownerCity: string;
	ownerZip: string;

	ownerState: Nullable<State>;
	ownerStateId: Nullable<number>;

	ownerNotes: string;

	currentStatus: Nullable<CurrentStatus>;
	currentStatusId: number;

	services?: Array<PetPrice>;
	priceType: PriceType;

	invoice: Nullable<Invoice>;
	invoiceId: Nullable<number>;

	initStatus: boolean;
	files: Array<PetFile>;

	invoiceSummary: Nullable<PetInvoiceSummary>;

	outOfDate?: boolean;
}

export interface PetListInfo extends WithId {
	name: string;

	ownerFirstName: string;
	ownerLastName: string;

	petSpecieId: number;
	petSpecie: PetSpecie;

	petBreedId: number;
	petBreed: PetBreed;
	isMixed: boolean;

	petSource: PetSource;

	actualWeight: number;
	reportedWeight: number;

	clinic?: Clinic | null;
	crematory: Crematory | null;

	invoice: Invoice;
	invoiceId: number | null;

	currentStatus: CurrentStatus;
	currentStatusId: number;

	serviceType: ServiceType;
	isSpecial: boolean;
	rush: boolean;
	onHold: PetOnHold;

	deliveryType: DeliveryType;

	gender: Gender | null;
	genderId: number;

	color: string;

	createdDate: number;
	internalIdNum: string;
}

export interface InvoicePet extends WithId {
	name: string;
	ownerFirstName: string;
	ownerLastName: string;
	actualWeight: number;
	reportedWeight: number;
	enteredDate: number;
	serviceType: number;
	invoiceSummary: Nullable<PetInvoiceSummary>;
}

export function getServiceTypeName(
	serviceType: ServiceType,
	t: TFunction,
	isSpecial: boolean = false,
	isAustralia: boolean = false,
	rush?: boolean,
): string {
	let type: string = '';

	switch (serviceType) {
	case ServiceType.All:
		return t('enums.service-type.all');

	case ServiceType.Private:
		type = t(`enums.service-type.${isAustralia ? 'aus-private' : 'private'}`);
		break;

	case ServiceType.Communal:
		type = t('enums.service-type.communal');
		break;
	case ServiceType.SemiPrivate:
		type = t(`enums.service-type.${isAustralia ? 'aus-semiprivate' : 'semiprivate'}`);
		break;
	case ServiceType.Storage:
		type = t('enums.service-type.storage');
		break;
	case ServiceType.ProductsOnly:
		type = t('enums.service-type.products-only');
		break;

	default:
		return 'Unknown Service Type';
	}

	if (rush) {
		type += ` ${t('filters.rush')}`;
	}

	if (isSpecial) {
		type += ` ${t('filters.is-special')}`;
	}

	return type;
}

export function getDeliveryTypeName(deliveryType: DeliveryType): string {
	let type: string = '';

	switch (deliveryType) {
	case DeliveryType.HomeDropOff:
		return 'Home drop off';

	case DeliveryType.VetDropOff:
		type = 'Vet drop off';
		break;

	case DeliveryType.Clinic:
		type = 'Clinic';
		break;

	case DeliveryType.Mail:
		type = 'Mail';
		break;

	case DeliveryType.Pickup:
		type = 'Pick up';
		break;

	case DeliveryType.None:
		type = 'None';
		break;

	default:
		return 'Unknown Service Type';
	}

	return type;
}

export interface ClinicPet extends WithId {
	name: string;
	engraving: Nullable<Array<PetEngraving>>;

	ownerFirstName: string;
	ownerLastName: string;
	ownerEmail: string;
	ownerPhone: string;

	petSpecieId: number;
	petSpecie: string;

	petBreedId: number;
	petBreed: string;

	petSource: PetSource;

	crematoryId: number;
	crematory: string;

	color: string;
	reportedWeight: number;

	genderId: number;
	gender: string;

	serviceType: ServiceType;
	isSpecial: boolean;

	clinicLocationId: Nullable<number>;
	clinicLocation: Nullable<LocationInfo>;

	deliveryClinicId: Nullable<number>;

	discountId: Nullable<number>;
	discount: Nullable<string>;

	services: Array<PetPrice>;
	specialInstructions: string;

	isMixed: boolean;
	noAshes: boolean;

	onHold: PetOnHold;
	rush: boolean;
	isEditable: boolean;

	deliveryType: DeliveryType;
	deliveryAddress: string;
	deliveryAddress2: string;
	deliveryCity: string;
	deliveryZip: string;
	deliveryStateId: Nullable<number>;
	deliveryState: Nullable<State>;

	internalIdNum: string;

	files: Array<PetFile>;
}

export function getDefaultClinicPet(): ClinicPet {
	return {
		id: -1,

		clinicLocationId: null,
		clinicLocation: null,
		deliveryClinicId: null,

		name: '',
		engraving: [],
		reportedWeight: 0,

		crematoryId: -1,
		crematory: '',

		color: '',

		genderId: -1,
		gender: '',

		petBreed: '',
		petBreedId: -1,
		isMixed: false,

		petSpecie: '',
		petSpecieId: -1,

		petSource: PetSource.Internal,

		ownerFirstName: '',
		ownerLastName: '',
		ownerPhone: '',
		ownerEmail: '',

		serviceType: ServiceType.Private,
		isSpecial: false,
		noAshes: false,

		services: [],
		specialInstructions: '',

		discountId: null,
		discount: '',

		onHold: PetOnHold.None,
		rush: false,
		isEditable: true,

		deliveryType: DeliveryType.None,
		deliveryAddress: '',
		deliveryAddress2: '',
		deliveryCity: '',
		deliveryStateId: null,
		deliveryZip: '',
		deliveryState: null,

		internalIdNum: '',

		files: [],
	};
}

export function getDefaultPet(): Pet {
	return {
		id: -1,

		ownerFirstName: '',
		ownerLastName: '',
		ownerAddress: '',
		ownerAddress2: '',
		ownerCity: '',
		ownerState: null,
		ownerStateId: null,
		ownerZip: '',
		ownerEmail: '',
		ownerPhone: '',
		ownerPhone2: '',
		ownerNotes: '',

		clinicId: null,
		clinicLocationId: null,
		clinicName: '',

		crematoryId: -1,

		urns: [],
		products: [],

		actualWeight: 0,
		reportedWeight: 0,
		ashesWeight: 0,

		color: '',
		createdDate: new Date().getTime(),
		receivedDate: new Date().getTime(),

		currentStatus: null,
		currentStatusId: -1,

		deliveryAddress: '',
		deliveryAddress2: '',
		deliveryCity: '',
		deliveryState: null,
		deliveryStateId: null,
		deliveryZip: '',
		deliveryClinic: null,
		deliveryClinicId: null,
		deliveryType: DeliveryType.None,

		gender: null,
		genderId: -1,

		petBreed: null,
		petBreedId: -1,
		isMixed: false,
		petSpecie: null,
		petSpecieId: -1,
		petSource: 0,

		invoice: null,
		invoiceId: null,
		discountId: null,
		discount: null,

		initStatus: false,

		name: '',
		engraving: [],
		email: '',
		phone: '',
		userId: -1,
		vet: '',
		internalIdNum: '',

		priceType: PriceType.Wholesale,
		serviceType: ServiceType.Private,
		isSpecial: false,

		onHold: PetOnHold.None,
		rush: false,

		spreadLat: null,
		spreadLong: null,

		specialInstructions: '',
		notes: [],

		batchActionId: null,
		batchAction: null,
		batchMachineLocation: '',

		files: [],

		invoiceSummary: {
			servicesTotal: 0,
			productsTotal: 0,
			deliveryTotal: 0,
			subTotal: 0,

			serviceTax: 0,
			serviceTaxPercentage: 0,
			productTax: 0,
			productTaxPercentage: 0,

			total: 0,
		},
	};
}

export enum FormType {
	horizontal= 'horizontal'
}

type TypeTester = (value: string | number) => boolean;

interface DeliveryTypeManager {
	isHomeDropOff: TypeTester;
	isVetDropOff: TypeTester;
	isClinic: TypeTester;
	isMail: TypeTester;
	isPickup: TypeTester;
	isNone: TypeTester;
	is: (value: string | number, oneOf: Array<DeliveryType>) => boolean;
}

export const deliveryTypeManager: DeliveryTypeManager = {
	isHomeDropOff: (value: string | number) => +value === DeliveryType.HomeDropOff,
	isVetDropOff: (value: string | number) => +value === DeliveryType.VetDropOff,
	isClinic: (value: string | number) => +value === DeliveryType.Clinic,
	isMail: (value: string | number) => +value === DeliveryType.Mail,
	isPickup: (value: string | number) => +value === DeliveryType.Pickup,
	isNone: (value: string | number) => +value === DeliveryType.None,
	is: (value: string | number, oneOf: Array<DeliveryType>) => {
		const cast = +value;

		return oneOf.some((item: DeliveryType) => cast === item);
	},
};

export function filterServices(services: Array<PetPrice>): Array<PetPrice> {
	return services.filter((q: PetPrice) => {
		const type = q.price?.specialService?.specialServiceType;

		return type === SpecialServiceType.Kit || type === SpecialServiceType.Service;
	});
}

/**
 * Check if engraving should be updated with pet's name on last one being changed
 * @param name - pet's name
 * @param engraving - current engraving's value
 */
export function shouldUpdateEngraving(name: string, engraving: Nullable<Array<PetEngraving>>): boolean {
	if (!engraving) return true;

	const available = engraving.filter((item: PetEngraving) => !item.removed);

	if (!available.length) return true;
	if (available.length > 1) return false;

	return available[0].name === name;
}

/**
 * Update engraving with pet's name
 * @param name - pet's name
 * @param engraving - current engraving list
 */
export function updateEngraving(name: string, engraving: Nullable<Array<PetEngraving>>): Array<PetEngraving> {
	const valid = engraving ?? [];
	const available = valid.filter((item: PetEngraving) => !item.removed);

	if (available.length === 0) {
		return [
			{
				id: -1,
				order: 0,
				name,
				removed: false,
			},
			...valid,
		];
	}

	if (available.length > 1) return valid;

	const index = valid.findIndex((item: PetEngraving) => !item.removed);

	return valid.map((item: PetEngraving, idx: number) => {
		if (idx !== index) return item;

		return {
			...item,
			name,
		};
	});
}

/**
 * Update engraving with pet's name
 * @param name - pet's name
 * @param engraving - current engraving list
 * @param availablePrices - available engraving's prices
 * @param serviceType - pet's service type
 * @param prefillEngraving - crematory's setting
 */
export function needUpdateEngraving(
	name: string,
	engraving: Nullable<Array<PetEngraving>>,
	availablePrices: Array<Price>,
	serviceType?: ServiceType,
	prefillEngraving?: boolean,
) {
	return serviceType !== ServiceType.Communal
	&& shouldUpdateEngraving(name, engraving)
	&& availablePrices.length
	&& prefillEngraving;
}
