import { WithFile } from '@common/typescript/objects/WithFile';
import { isPresent } from '@common/typescript/objects/Nullable';

import {
	Pet,
	PetPrice,
	ServiceType,
} from '@app/objects/Pet';
import {
	CreatePetMessage,
	UpdatePetMessage,
	PetFormValues,
	ReturnValues,
	UpdatePetPriceMessage,
} from '@app/components/Pages/PetEditor/OldPetEditor/Types';
import { UserRole } from '@app/objects/User';
import { PriceKind } from '@app/objects/Price';
import { PetFile } from '@app/objects/PetFile';
import { SpecialServiceType } from '@app/objects/SpecialService';
import {
	StoreEntryAttribute, StoreEntryAttributeValue, StoreEntryNode, getPath,
} from '@app/objects/StoreEntry';

export function toCreateMessage(values: ReturnValues): CreatePetMessage {
	const allServices = [...values.services, ...values.products, ...values.urns];
	const files = values.files.map((item: WithFile<Pet>) => ({ fileId: item.fileId }));

	return {
		actualWeight: Math.max(values.actualWeight || 0, 0),
		reportedWeight: Math.max(values.reportedWeight || 0, 0),
		ashesWeight: Math.max(values.ashesWeight || 0, 0),

		crematoryId: values.crematoryId,
		clinicId: values.clinicId,
		clinicLocationId: values.clinicLocationId,

		name: values.name,
		engraving: values.engraving ?? [],
		color: values.color,
		onHold: values.onHold,

		deliveryAddress: values.deliveryAddress,
		deliveryAddress2: values.deliveryAddress2,
		deliveryCity: values.deliveryCity,
		deliveryClinicId: values.deliveryClinicId,
		deliveryStateId: values.deliveryStateId,
		deliveryType: values.deliveryType,
		deliveryZip: values.deliveryZip,

		carrierId: values.carrierId ?? null,
		trackingNumber: values.trackingNumber ?? '',

		genderId: values.genderId,
		discountId: values.discountId,

		serviceType: values.serviceType,
		priceType: values.priceType,

		ownerAddress: values.ownerAddress,
		ownerAddress2: values.ownerAddress2,
		ownerCity: values.ownerCity,
		ownerEmail: values.ownerEmail,
		ownerFirstName: values.ownerFirstName,
		ownerLastName: values.ownerLastName,
		ownerNotes: values.ownerNotes,
		ownerPhone: values.ownerPhone,
		ownerPhone2: values.ownerPhone2,
		ownerStateId: values.ownerStateId,
		ownerZip: values.ownerZip,

		petSpecieId: values.petSpecieId,
		petBreedId: values.petBreedId,
		isMixed: values.isMixed,
		rush: values.rush,

		receivedDate: values.receivedDate,
		services: allServices.map((item: PetPrice) => ({
			id: item.id,
			priceId: item.priceId,
			nodeId: item.nodeId,
			name: item.name,

			count: item.count,
			value: item.value,
			extra: item.extra,

			note: item.note,
		})) ?? [],

		spreadLat: values.serviceType === ServiceType.Communal ? values.spreadLat : null,
		spreadLong: values.serviceType === ServiceType.Communal ? values.spreadLong : null,

		specialInstructions: values.specialInstructions,
		internalIdNum: values.internalIdNum,
		vet: '',
		files,
	};
}

export function toUpdateMessage(values: PetFormValues | ReturnValues, userRole?: UserRole): UpdatePetMessage {
	const allServices = [...values.services ?? [], ...values.products, ...values.urns];
	const services: Array<UpdatePetPriceMessage> = allServices
		.filter((q: PetPrice) =>
			!q.price
			|| q.price.specialServiceId !== null
			|| q.price.priceKind === PriceKind.ProductPrice
			|| q.price.priceKind === PriceKind.UrnPrice
			|| q.price.priceKind === PriceKind.PickupPrice)
		.map((service: PetPrice): UpdatePetPriceMessage => ({
			id: service.id,
			removed: service.removed ?? false,

			priceId: service.priceId,
			nodeId: service.nodeId,
			count: service.count,
			value: service.value,
			note: service.note,
		}));

	const crematoryId = userRole && userRole === UserRole.Admin ? values.crematoryId : undefined;
	const files = values.files.map((item: PetFile) => ({
		fileId: item.fileId,
		id: item.id,
		objectId: item.objectId,
		text: item.text,
		deleted: item.deleted,
	}));

	return {
		id: values.id,

		actualWeight: Math.max(values.actualWeight || 0, 0),
		reportedWeight: Math.max(values.reportedWeight || 0, 0),
		ashesWeight: Math.max(values.ashesWeight || 0, 0),

		clinicId: values.clinicId,
		clinicLocationId: values.clinicLocationId,

		crematoryId,

		name: values.name,
		engraving: values.engraving ?? [],
		color: values.color,
		onHold: values.onHold,

		deliveryAddress: values.deliveryAddress,
		deliveryAddress2: values.deliveryAddress2,
		deliveryCity: values.deliveryCity,
		deliveryClinicId: values.deliveryClinicId,
		deliveryStateId: values.deliveryStateId,
		deliveryType: values.deliveryType,
		deliveryZip: values.deliveryZip,

		carrierId: values.carrierId ?? null,
		trackingNumber: values.trackingNumber ?? '',

		genderId: values.genderId,
		discountId: values.discountId,

		serviceType: values.serviceType,
		priceType: values.priceType,

		ownerAddress: values.ownerAddress,
		ownerAddress2: values.ownerAddress2,
		ownerCity: values.ownerCity,
		ownerEmail: values.ownerEmail,
		ownerFirstName: values.ownerFirstName,
		ownerLastName: values.ownerLastName,
		ownerNotes: values.ownerNotes,
		ownerPhone: values.ownerPhone,
		ownerPhone2: values.ownerPhone2,
		ownerStateId: values.ownerStateId,
		ownerZip: values.ownerZip,

		petSpecieId: values.petSpecieId,
		petBreedId: values.petBreedId,
		isMixed: values.isMixed,
		rush: values.rush,

		receivedDate: values.receivedDate,
		isSpecial: values.isSpecial,

		spreadLat: values.serviceType === ServiceType.Communal ? values.spreadLat : null,
		spreadLong: values.serviceType === ServiceType.Communal ? values.spreadLong : null,

		specialInstructions: values.specialInstructions,
		internalIdNum: values.internalIdNum,
		vet: values.vet,

		updateServices: services,

		recalculatePrice: values.recalculatePrice,

		files,
	};
}

// Calculate full internationalization dictionary key
export function iKey(key: string): string {
	return `editors.pet-editor.${key}`;
}

export function updateSpecialServiceKit(services: Array<PetPrice>): Array<PetPrice> {
	return services.map((i: PetPrice) => {
		if (i.price?.specialService?.specialServiceType === SpecialServiceType.Kit) {
			const parent = i;

			const children = services.filter((c) => c.price?.specialService?.parentId === parent.price?.specialServiceId);
			const completed = children.reduce((prev, cur) => prev && cur.done, true);

			return { ...parent, done: completed };
		}

		return i;
	});
}

const condition = (isClinic: boolean, item: StoreEntryNode, value: StoreEntryAttributeValue) => (isClinic
	? value.id === item.valueId && !value.attribute?.clinicCanPick
	: value.id === item.valueId);

export function getUrnSegments(record: PetPrice, isClinic: boolean = false): Array<string> {
	const entry = record?.entry;
	const id = record.nodeId;
	if (!entry) return [];

	const node = record.node ?? entry.tree.find((item: StoreEntryNode) => item.id === id);
	if (!node) return [];

	const path = getPath(entry, node);
	if (!path.length) return [];

	const values = entry.attributes.flatMap((attribute: StoreEntryAttribute) => attribute.values);

	return path.map((item: StoreEntryNode) => {
		if (item.valueId === null) return null;

		return values.find((v: StoreEntryAttributeValue) => condition(isClinic, item, v))?.name;
	}).filter(isPresent);
}

const unknown: string = 'Unknown Urn';
export function getUrnFullName(record: PetPrice): string {
	const entry = record?.entry;
	if (!entry) return unknown;

	const segments = getUrnSegments(record);
	segments.unshift(entry.name);

	return segments.join(' ');
}
