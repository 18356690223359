import {
	Action,
	Reducer,
	ReducersMapObject,
} from 'redux';

import { BaseApplicationState, BaseAppThunkAction, baseReducers } from '@common/react/store';
import { CommentsState, getReducer as getCommentsReducer } from '@common/react/store/Comments';
import { ItemState } from '@common/react/store/Item';
import { ItemsState } from '@common/react/store/ItemList';

import { User, UserListInfo } from '@app/objects/User';
import {
	Pet, PetBreed, PetListInfo, Gender, PetSpecie,
} from '@app/objects/Pet';
import { Clinic, ClinicListInfo, ClinicSelectInfo } from '@app/objects/Clinic';
import { Crematory, CrematoryListInfo } from '@app/objects/Crematory';
import { Location } from '@app/objects/Location';
import { SpecialService } from '@app/objects/SpecialService';
import { Price } from '@app/objects/Price';
import { Invoice } from '@app/objects/Invoice';
import { Status } from '@app/objects/Status';
import { Texts } from '@app/objects/Texts';
import { ClinicCrematory } from '@app/objects/ClinicCrematory';
import { CrematoryUserListInfo } from '@app/objects/CrematoryUser';
import * as CrematoryUserDashboard from '@app/store/CrematoryUserDashboard';
import { StatusOrder } from '@app/objects/StatusOrder';
import { PetListSpecialServiceView } from '@app/objects/PetListSpecialServiceView';
import { PetListStatusView } from '@app/objects/PetListStatusView';
import { getItemReducer } from '@app/store/Item';
import { getItemsReducer } from '@app/store/ItemList';
import { notLoggedReducer, NotLoggedState } from '@app/store/NotLogged';
import { getUserPreferencesReducer, UserPreferencesStore } from '@app/store/UserPreferences/UserPreferences';
import { CrematoryPreferencesState, getCrematoryPreferencesReducer } from '@app/store/CrematoryPreferences';
import { InventoryItem } from '@app/objects/Inventory';
import { Carrier } from '@app/objects/Carriers';
import { CrematoryUserCounterEntity } from '@app/objects/CrematoryUserCounter';
import { listStoreReducer, SelectList } from '@app/store/SelectList/SelectList';
import { DashboardCountersState, getDashboardCountersReducer } from '@app/store/DashboardCounters';
import { DashboardCounters } from '@app/objects/Dashboard';
import { CrematoryFeatures } from '@app/objects/CrematoryFeatures';
import { CrematoryFeaturesState, getCrematoryFeaturesReducer } from '@app/store/CrematoryFeatures';
import { getPetStatusTriggerReducer, PetStatusTriggerState } from '@app/store/PetTriggers';

export interface ApplicationState extends BaseApplicationState<User> {
	carriers: ItemsState<Carrier>;

	clinic: ItemState<Clinic>;
	clinics: ItemsState<ClinicListInfo>;
	clinicSelectInfos: ItemsState<ClinicSelectInfo>;
	clinicLocations: ItemsState<Location>;
	clinicLocation: ItemState<Location>;
	clinicCrematories: ItemsState<ClinicCrematory>;
	crematoryAllPrices: ItemsState<Price>;

	clinicPricesForClinicCommunal: ItemsState<Price>;
	clinicPricesForClinicSemiPrivate: ItemsState<Price>;
	clinicPricesForClinicPrivate: ItemsState<Price>;
	clinicPricesForClinicSpecialCommunal: ItemsState<Price>;
	clinicPricesForClinicStorage: ItemsState<Price>;
	clinicPricesForClinicProductsOnly: ItemsState<Price>;

	clinicPricesForRetailCommunal: ItemsState<Price>;
	clinicPricesForRetailSemiPrivate: ItemsState<Price>;
	clinicPricesForRetailPrivate: ItemsState<Price>;
	clinicPricesForRetailSpecialCommunal: ItemsState<Price>;
	clinicPricesForRetailStorage: ItemsState<Price>;
	clinicPricesForRetailProductsOnly: ItemsState<Price>;

	comments: CommentsState<User>;

	crematory: ItemState<Crematory>;
	crematories: ItemsState<CrematoryListInfo>;

	crematoryPricesForClinicCommunal: ItemsState<Price>;
	crematoryPricesForClinicSpecialCommunal: ItemsState<Price>;
	crematoryPricesForClinicSemiPrivate: ItemsState<Price>;
	crematoryPricesForClinicPrivate: ItemsState<Price>;
	crematoryPricesForClinicStorage: ItemsState<Price>;
	crematoryPricesForClinicProductsOnly: ItemsState<Price>;

	crematoryPricesForRetailCommunal: ItemsState<Price>;
	crematoryPricesForRetailSpecialCommunal: ItemsState<Price>;
	crematoryPricesForRetailPrivate: ItemsState<Price>;
	crematoryPricesForRetailSemiPrivate: ItemsState<Price>;
	crematoryPricesForRetailStorage: ItemsState<Price>;
	crematoryPricesForRetailProductsOnly: ItemsState<Price>;

	crematoryInvoices: ItemsState<Invoice>;
	crematoryUsers: ItemsState<CrematoryUserListInfo>;
	crematoryPreferences: CrematoryPreferencesState;
	crematoryFeatures: CrematoryFeaturesState<CrematoryFeatures>;
	crematoryUserDashboardServices: CrematoryUserDashboard.CrematoryUserDashboardState<CrematoryUserCounterEntity.SpecialService>;
	crematoryUserDashboardStatus: CrematoryUserDashboard.CrematoryUserDashboardState<CrematoryUserCounterEntity.Status>;

	dashboardCounters: DashboardCountersState<DashboardCounters>;

	invoice: ItemState<Invoice>;
	inventoryItem: ItemState<InventoryItem>;

	notLogged: NotLoggedState;

	pet: ItemState<Pet>;
	pets: ItemsState<PetListInfo>;
	petSpecies: ItemsState<PetSpecie>;
	petBreeds: ItemsState<PetBreed>;
	gender: ItemsState<Gender>;
	price: ItemState<Price>;
	petStatusDetails: ItemsState<PetListStatusView>;
	petSpecialServiceDetails: ItemsState<PetListSpecialServiceView>;

	specialService: ItemState<SpecialService>;
	specialServices: ItemsState<SpecialService>;

	statuses: ItemsState<Status>;
	statusOrders: PetStatusTriggerState<StatusOrder>;

	texts: ItemsState<Texts>;

	user: ItemState<User>;
	users: ItemsState<UserListInfo>;

	userPreferences: UserPreferencesStore;

	// Select component data
	selects: SelectList;

	// Pet for modal
	petModal: ItemState<Pet>;
}

const coreReducers: ReducersMapObject<Omit<ApplicationState, 'selects'>> = {
	...baseReducers as ReducersMapObject<BaseApplicationState<User>>,

	carriers: getItemsReducer<Carrier>('carriers'),
	clinic: getItemReducer<Clinic>('clinic'),
	clinics: getItemsReducer<ClinicListInfo>('clinics'),
	clinicSelectInfos: getItemsReducer<ClinicSelectInfo>('clinicSelectInfos'),

	clinicPricesForClinicCommunal: getItemsReducer<Price>('clinicPricesForClinicCommunal'),
	clinicPricesForClinicSemiPrivate: getItemsReducer<Price>('clinicPricesForClinicSemiPrivate'),
	clinicPricesForClinicPrivate: getItemsReducer<Price>('clinicPricesForClinicPrivate'),
	clinicPricesForClinicSpecialCommunal: getItemsReducer<Price>('clinicPricesForClinicSpecialCommunal'),
	clinicPricesForClinicStorage: getItemsReducer<Price>('clinicPricesForClinicStorage'),
	clinicPricesForClinicProductsOnly: getItemsReducer<Price>('clinicPricesForClinicProductsOnly'),

	clinicPricesForRetailCommunal: getItemsReducer<Price>('clinicPricesForRetailCommunal'),
	clinicPricesForRetailSemiPrivate: getItemsReducer<Price>('clinicPricesForRetailSemiPrivate'),
	clinicPricesForRetailPrivate: getItemsReducer<Price>('clinicPricesForRetailPrivate'),
	clinicPricesForRetailSpecialCommunal: getItemsReducer<Price>('clinicPricesForRetailSpecialCommunal'),
	clinicPricesForRetailStorage: getItemsReducer<Price>('clinicPricesForRetailStorage'),
	clinicPricesForRetailProductsOnly: getItemsReducer<Price>('clinicPricesForRetailProductsOnly'),

	clinicLocations: getItemsReducer<Location>('clinicLocations'),
	clinicLocation: getItemReducer<Location>('clinicLocation'),
	clinicCrematories: getItemsReducer<ClinicCrematory>('clinicCrematories'),

	comments: getCommentsReducer(),

	crematory: getItemReducer<Crematory>('crematory'),
	crematories: getItemsReducer<CrematoryListInfo>('crematories'),
	crematoryInvoices: getItemsReducer<Invoice>('crematoryInvoices'),
	crematoryAllPrices: getItemsReducer<Price>('crematoryAllPrices'),

	crematoryPricesForClinicCommunal: getItemsReducer<Price>('crematoryPricesForClinicCommunal'),
	crematoryPricesForClinicSpecialCommunal: getItemsReducer<Price>('crematoryPricesForClinicSpecialCommunal'),
	crematoryPricesForClinicSemiPrivate: getItemsReducer<Price>('crematoryPricesForClinicSemiPrivate'),
	crematoryPricesForClinicStorage: getItemsReducer<Price>('crematoryPricesForClinicStorage'),
	crematoryPricesForClinicPrivate: getItemsReducer<Price>('crematoryPricesForClinicPrivate'),
	crematoryPricesForClinicProductsOnly: getItemsReducer<Price>('crematoryPricesForClinicProductsOnly'),

	crematoryPricesForRetailCommunal: getItemsReducer<Price>('crematoryPricesForRetailCommunal'),
	crematoryPricesForRetailSpecialCommunal: getItemsReducer<Price>('crematoryPricesForRetailSpecialCommunal'),
	crematoryPricesForRetailPrivate: getItemsReducer<Price>('crematoryPricesForRetailPrivate'),
	crematoryPricesForRetailSemiPrivate: getItemsReducer<Price>('crematoryPricesForRetailSemiPrivate'),
	crematoryPricesForRetailStorage: getItemsReducer<Price>('crematoryPricesForRetailStorage'),
	crematoryPricesForRetailProductsOnly: getItemsReducer<Price>('crematoryPricesForRetailProductsOnly'),

	crematoryUsers: getItemsReducer<CrematoryUserListInfo>('crematoryUsers'),
	crematoryPreferences: getCrematoryPreferencesReducer() as Reducer<CrematoryPreferencesState, Action>,
	crematoryFeatures: getCrematoryFeaturesReducer(),

	crematoryUserDashboardServices:
		CrematoryUserDashboard.getReducer<CrematoryUserCounterEntity.SpecialService>(CrematoryUserCounterEntity.SpecialService),
	crematoryUserDashboardStatus:
		CrematoryUserDashboard.getReducer<CrematoryUserCounterEntity.Status>(CrematoryUserCounterEntity.Status),

	dashboardCounters: getDashboardCountersReducer<DashboardCounters>(),

	invoice: getItemReducer<Invoice>('invoice'),
	inventoryItem: getItemReducer<InventoryItem>('inventoryItem'),

	notLogged: notLoggedReducer,

	pet: getItemReducer<Pet>('pet'),
	pets: getItemsReducer<PetListInfo>('pets'),
	petSpecies: getItemsReducer<PetSpecie>('petSpecies'),
	petBreeds: getItemsReducer<PetBreed>('petBreeds'),
	gender: getItemsReducer<Gender>('gender'),
	price: getItemReducer<Price>('price'),
	petStatusDetails: getItemsReducer<PetListStatusView>('petStatusDetails'),
	petSpecialServiceDetails: getItemsReducer<PetListSpecialServiceView>('petSpecialServiceDetails'),

	specialService: getItemReducer<SpecialService>('specialService'),
	specialServices: getItemsReducer<SpecialService>('specialServices'),

	statuses: getItemsReducer<Status>('statuses'),
	statusOrders: getPetStatusTriggerReducer<StatusOrder>(),

	texts: getItemsReducer<Texts>('texts'),

	user: getItemReducer<User>('user'),
	users: getItemsReducer<UserListInfo>('users'),

	userPreferences: getUserPreferencesReducer() as Reducer<UserPreferencesStore, Action>,

	petModal: getItemReducer<Pet>('petModal'),
};

export const reducers = {
	...coreReducers,
	selects: listStoreReducer,
};

export type AppThunkAction<TAction extends Action> = BaseAppThunkAction<TAction, User, ApplicationState>;
